import React from 'react';
import { Layout, Menu, Typography, Flex } from 'antd-v5';
import Auth from '../../Auth';
import { NavLink } from "react-router-dom";
import Icon, { AppstoreOutlined, CalendarOutlined, ClockCircleOutlined, TeamOutlined } from '@ant-design/icons';
const { Footer } = Layout;
const { Text } = Typography;


const items = [
  {
    label: (
      <a href="#/app/my-schedule" rel="noopener noreferrer">
        My Schedule
      </a>
    ),
    key: 'my-records',
  },
  {
    label: (
      <a href="#/app/my-records" rel="noopener noreferrer">
        My Records
      </a>
    ),
    key: 'my-schedule',
  },
  {
    label: (
      <a href="#/app/crew-builder" rel="noopener noreferrer">
        Crew Builder
      </a>
    ),
    key: 'crew-builder',
  },
]

export const MobileNav = (props) => {
  const { mainMenuCollapse, onMainMenuCollapse } = props

  return (
    <Footer style={{ textAlign: 'center', bottom: 0, marginLeft: -16, marginRight: -16, marginTop: 24, position: "sticky", padding: 0, zIndex: 10 }} id="mobileNavigation">
      <Menu
        theme="dark"
        mode="horizontal"
        style={{
          flex: 1,
          minWidth: 0,
          justifyContent: "center"
        }}>
        {
          <Menu.Item onClick={() => onMainMenuCollapse(!!!mainMenuCollapse)}>
            <Flex align="center" vertical style={{ paddingTop: 12, paddingBottom: 12 }}>
              <AppstoreOutlined style={{ fontSize: 20 }} />
              <Text style={{ color: "unset", marginLeft: 0, fontSize: 10, marginTop: 4 }}>Main Menu</Text>
            </Flex>
          </Menu.Item>
        }
        {
          Auth.hasPermission('web.crew_builder') &&
          <Menu.Item key="crew-builder" >
            <NavLink to="/app/crew-builder">
              <Flex align="center" vertical style={{ paddingTop: 12, paddingBottom: 12 }}>
                <TeamOutlined style={{ fontSize: 20 }} />
                <Text style={{ color: "unset", marginLeft: 0, fontSize: 10, marginTop: 4 }}>Crew Builder</Text>
              </Flex>
            </NavLink>
          </Menu.Item>
        }
        {
          Auth.hasPermission('web.timecards') &&
          <Menu.Item key="my-schedule">
            <NavLink Link to="/app/my-schedule">
              <Flex align="center" vertical style={{ paddingTop: 12, paddingBottom: 12 }}>
                <CalendarOutlined style={{ fontSize: 20 }} />
                <Text style={{ color: "unset", marginLeft: 0, fontSize: 10, marginTop: 4 }}>My Schedule</Text>
              </Flex>
            </NavLink>
          </Menu.Item>
        }
        {
          Auth.hasPermission('web.timecards') &&
          <Menu.Item key="my-records">
            <NavLink to="/app/my-records">
              <Flex align="center" vertical style={{ paddingTop: 12, paddingBottom: 12 }}>
                <ClockCircleOutlined style={{ fontSize: 20 }} />
                <Text style={{ color: "unset", marginLeft: 0, fontSize: 10, marginTop: 4 }}>My Records</Text>
              </Flex>
            </NavLink>
          </Menu.Item>
        }
      </Menu>
    </Footer >
  )
}