import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Divider, Input, Button, Space, Breadcrumb, Card, Switch, Tag, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Auth from '../../Auth';

const { Text } = Typography;

export class ListUsers extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        users: [],
        error: null,
        includeInactive: false,
    };

    componentDidMount() {
        this.fetchUsers()
    }

    fetchUsers(includeInactive = false) {

        const setLoading = this.props.setLoading;
        setLoading(true);

        console.log(includeInactive)
        fetch(`api/users?includeRole=true&companyId=${Auth.getCurrentUser()?.companyId}&excludeInactive=${!includeInactive}`)
            .then(response => response.json())
            .then((result) => {
                this.setState({
                    users: result.map(u => ({ ...u, key: u.id, role: u.role?.name })),
                });
                setLoading(false);
            },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleInactiveFilter = includeInactive => {
        this.setState({ includeInactive: includeInactive })
        this.fetchUsers(includeInactive);
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '30%',
                // fixed: 'left',
                ...this.getColumnSearchProps('name'),
                render: (value, row) => <>{!row.isActive && <Tag color="#f50">Inactive</Tag>} {value}</>
            },
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username',
                width: '20%',
                ...this.getColumnSearchProps('username'),
            },
            {
                title: 'Employee Code',
                dataIndex: 'employeeCode',
                key: 'employeeCode',
                // fixed: 'left',
                ...this.getColumnSearchProps('employeeCode'),
            },
            {
                title: 'Email Address',
                dataIndex: 'email',
                key: 'email',
                ...this.getColumnSearchProps('email'),
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: '20%',
                ...this.getColumnSearchProps('role'),
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (user) => {
                    return (
                        Auth.hasPermission('users.update') &&
                        <Space size="small" wrap={true}>

                            <Button size="small" type="primary" href={`#/users/${user.id}/edit`}>
                                Edit
                            </Button>
                        </Space >
                    )
                },
            },
        ];
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Users</Breadcrumb.Item>
                    <Breadcrumb.Item>Search</Breadcrumb.Item>
                </Breadcrumb>
                <Divider />


                <Table
                    columns={columns}
                    dataSource={this.state.users}
                    scroll={{ x: 1300 }}
                    pagination={{ position: ["bottomLeft"] }}
                    footer={() =>
                        <>
                            <Text style={{ marginRight: 12 }}>Include Inactive</Text>
                            <Switch
                                size="small"
                                onChange={(value) => {
                                    this.setState({ includeInactive: value })
                                    this.fetchUsers(value);
                                }}
                                value={this.state.includeInactive}
                            />
                        </>
                    }
                />

            </>
        );
    }
}