import { MinusCircleOutlined, PlusOutlined, MoonOutlined } from '@ant-design/icons';
import { Alert, Badge, Breadcrumb, Button, Descriptions, Card, ConfigProvider as ConfigProvider5, DatePicker, Form, Input, InputNumber, Modal, notification, Select, Row, Col, Typography, Space } from 'antd-v5';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
import { MobileNav } from './Nav';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;

export const FormTimeRequests = ({ setLoading, location, productionId, isReadOnly = false, loading }) => {
    const uriParams = useParams();

    const [api, contextHolder] = notification.useNotification();
    const { id } = uriParams

    const isEditMode = id != null && id > 0

    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');

    const prefillJobId = new URLSearchParams(location.search).get("jobId");
    const prefillUserId = new URLSearchParams(location.search).get("userId");
    const prefillProducedAt = new URLSearchParams(location.search).get("producedAt");
    const prefillProducedEndsAt = new URLSearchParams(location.search).get("producedEndsAt");
    const prefillWorkType = new URLSearchParams(location.search).get("workType");
    const prefillDescription = new URLSearchParams(location.search).get("description");
    const productionRequestId = new URLSearchParams(location.search).get("productionRequestId");

    const [production, setProduction] = useState();
    const [job, setJob] = useState();
    const [workTypeList, setWorkTypeList] = useState([])
    const [computedHours, setComputedHours] = useState(0);
    const [isConfirming, setIsConfirming] = useState(false);
    const [readOnly, setReadOnly] = useState(isReadOnly);
    const [isOvernightWork, setIsOvernightWork] = useState(false);
    const [isNotAligned, setIsNotAligned] = useState(false);

    const [producedDate, setProducedDate] = useState();
    const [producedTimeStart, setProducedTimeStart] = useState();
    const [producedTimeEnd, setProducedTimeEnd] = useState();

    const [workType, setWorkType] = useState({});

    const fetchWorkTypes = async () => {
        setLoading(true)
        await fetch(`/api/work-types`)
            .then((response) => response.json())
            .then((workTypeResult) => {
                setWorkTypeList(workTypeResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const fetchJob = async () => {
        const jobId = prefillJobId;
        setLoading(true);
        await fetch(`/api/jobs/${jobId}?includeCostCodes=true&includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}&includeEquipmentUtilization=true`)
            .then((response) => response.json())
            .then((jobResult) => {
                setJob(jobResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const fetchProductionById = (id) => {
        setLoading(true);
        fetch(`/api/jobs/productions/${id}`)
            .then((response) => response.json())
            .then((prod) => {
                setLoading(false)
                return prod
            })
            .then((prod) => {
                setProduction(prod)
                setJob(prod.job)
                prod.producedAt = dayjs(prod.producedAt)

                if (prod.isApproved) {
                    setReadOnly(true)
                }

                form.setFieldsValue({
                    jobId: prod.jobId.toString(),
                    costCodeId: prod.costCodeId.toString(),
                    producedAt: prod.producedAt,
                    producedDate: dayjs(prod.producedAt).format("YYYY-MM-DD"),
                    producedTimeStart: dayjs(prod.producedAt).format("HH:mm:ss"),
                    producedTimeEnd: dayjs(prod.producedEndsAt).format("HH:mm:ss"),
                    quantity: prod.quantity,
                    workTypeId: prod.workTypeId,
                    description: prod.description,
                    productionEquipments: prod.productionEquipments,
                    userId: `${prod.user.name}`
                })

                computeHours()
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const onValuesChange = (changedValues) => {
        console.log({ changedValues })
    }

    const onWorkTypeSelect = (workTypeId) => {
        const selectedWorkType = workTypeList.find(x => x.id == workTypeId)
        setWorkType(selectedWorkType)
    }

    const computeHours = () => {
        const values = form.getFieldsValue();
        if (values.producedDate && values.producedTimeStart && values.producedTimeEnd) {
            const start = dayjs(`${values.producedDate}T${values.producedTimeStart}`);
            let end = dayjs(`${values.producedDate}T${values.producedTimeEnd}`);

            if (start.isAfter(end)) {
                end = end.add(1, "day")
                setIsOvernightWork(true)
            }
            else {
                setIsOvernightWork(false)
            }

            if (!start.isSame(prefillProducedAt) ||
                !end.isSame(prefillProducedEndsAt)) {
                setIsNotAligned(true)
            }

            setComputedHours(end.diff(start, "hours", true).toFixed(2));
            console.log("Compute")
        }
    }

    const onFinish = (isAddMore = false) => {

        setLoading(true)
        if (readOnly) return;
        const userId = Auth.getCurrentUser().id ?? prefillUserId;
        const jobId = job?.id ?? prefillJobId;

        const values = form.getFieldsValue();

        const start = dayjs(`${producedDate ?? values.producedDate}T${producedTimeStart ?? values.producedTimeStart}`)
        let end = dayjs(`${producedDate ?? values.producedDate}T${producedTimeEnd ?? values.producedTimeEnd}`)

        if (start.isAfter(end)) {
            end = end.add(1, "day")
            setIsOvernightWork(true)
        }

        var newRecord = {
            type: "Time Record",
            quantity: computedHours,
            producedAt: start.toISOString(),
            producedEndsAt: end.toISOString(),
            userId: userId,
            jobId: jobId,
            productionRequestId: productionRequestId,
            productionEquipments: values.productionEquipments,
            costCodeId: values.costCodeId,
            workTypeId: values.workTypeId,
            description: values.description,
        }

        if (isEditMode) {
            var updatedRecord = {
                quantity: computedHours,
                producedAt: start.toISOString(),
                producedEndsAt: end.toISOString(),
                userId: userId,
                jobId: jobId,
                productionRequestId: productionRequestId,
                productionEquipments: values.productionEquipments,
                costCodeId: values.costCodeId,
                workTypeId: values.workTypeId,
                description: values.description,
            }

            fetch(`api/jobs/productions/${id}`, {
                method: 'PUT', body: JSON.stringify(updatedRecord), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'Time Record has been updated successfully.',
                    });

                    setLoading(false);
                    setIsConfirming(false);
                });
            return;
        }

        fetch(`api/jobs/${prefillJobId}/productions`, {
            method: 'POST', body: JSON.stringify(newRecord), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                api.success({
                    message: 'Saved',
                    description:
                        'Time Record has been added successfully.',
                    onClose: () => {
                        if (isAddMore) {
                            window.location.reload()
                        }
                        else {
                            history.push("/app/my-records")
                        }


                        setLoading(false)
                    },
                    duration: 3,
                    showProgress: true,
                });


            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        isEditMode ? fetchProductionById(id) : fetchJob();
        fetchWorkTypes();

        if (!isEditMode) {
            form.setFieldValue("producedDate", dayjs(prefillProducedAt).format("YYYY-MM-DD"))
            // form.setFieldValue("producedTimeStart", dayjs(prefillProducedAt).format("HH:mm"))
            // form.setFieldValue("producedTimeEnd", dayjs(prefillProducedEndsAt).format("HH:mm"))
            computeHours()
        }
    }, [prefillJobId]);

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    const formUi = <Form
        name='FormProduction'
        form={form}
        {...formItemLayout}
        layout={formLayout}
        onFinish={() => {
            setIsConfirming(true)
        }}
        disabled={isConfirming || readOnly}
        onFinishFailed={onFinishFailed}
        autoComplete='on'
        onValuesChange={onValuesChange}
    >
        <div style={{ marginBottom: 32 }}>

            <Title level={5}>{job?.name} - {job?.description}</Title>


            {!isEditMode &&
                <Text type="secondary" style={{ marginBottom: 24 }}>
                    {dayjs(prefillProducedAt).format("ddd, MMM DD hh:mm a")} - {dayjs(prefillProducedEndsAt).format("ddd, MMM DD hh:mm a")}
                </Text>
            }

            {/* Wed, Nov 27 08:00 am – Wed, Nov 27, 02:00 pm */}
        </div>


        {/* 
        <Form.Item
            name="producedAtRange"
            label="Start & End Time"
            required
        >
            <RangePicker
                minDate={dayjs(prefillProducedAt).startOf("day")}
                maxDate={dayjs(prefillProducedEndsAt).endOf("day")}
                showTime={{
                    format: 'HH:mm',
                }}
                onChange={(dates) => {
                    const startAt = dayjs(dates[0]);
                    const endAt = dayjs(dates[1]);
                    setComputedHours(endAt.diff(startAt, 'hour', true).toFixed(2));
                }}
            />
        </Form.Item>
    </Badge> */}
        <Form.Item
            label="Start & End Time"
            required
            name="Time"
            rules={[{
                message: 'End time must be less than start time.',
                validator: (_, value) => {
                    const values = form.getFieldsValue();
                    console.log({ values })

                    if (!values.producedDate || !values.producedTimeStart || !values.producedTimeEnd) {
                        return Promise.reject('Date/Time range is invalid.');
                    }

                    const start = dayjs(`${values.producedDate}T${values.producedTimeStart}`);
                    const end = dayjs(`${values.producedDate}T${values.producedTimeEnd}`);

                    // if (start.isAfter(end)) {
                    //     return Promise.reject('Date/Time range is invalid.');
                    // }

                    return Promise.resolve();
                }
            }
            ]}
            onChange={computeHours}
        >
            <Form.Item
                style={{ margin: 0 }}
                name="producedDate"
            >
                <Input
                    type="date"
                    onChange={(e) => setProducedDate(e.target.value)}
                    min={dayjs(prefillProducedAt).format("YYYY-MM-DD")}
                    max={dayjs(prefillProducedEndsAt).format("YYYY-MM-DD")}
                />
            </Form.Item>
            <Badge
                count={`${computedHours} hours`}
                style={{
                    backgroundColor: '#52c41a',
                }}
                offset={[10, 12]}>

                <Space.Compact style={{ marginTop: 12 }}>
                    <Form.Item
                        style={{ display: 'inline-block', margin: 0 }}
                        name="producedTimeStart">
                        <Input
                            type="time"
                            onChange={(e) => setProducedTimeStart(e.target.value)}
                            required
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'inline-block', margin: 0 }}
                        name="producedTimeEnd">
                        <Input
                            type="time"
                            onChange={(e) => setProducedTimeEnd(e.target.value)}
                            required
                        />
                    </Form.Item>
                </Space.Compact>
            </Badge>
            {
                isNotAligned && isConfirming &&
                <Alert
                    style={{ marginTop: 12 }}
                    message="Info"
                    description="Hours entered will be reviewed by supervisor."
                    type="info"
                    showIcon />
            }
            {
                isOvernightWork && <Alert
                    style={{ marginTop: 12 }}
                    message="Overnight Work"
                    description="Your recorded time start and time end is considered overnight work."
                    type="info"
                    icon={<MoonOutlined />}
                    showIcon />
            }
        </Form.Item>

        <Form.Item
            label='Cost Code'
            name='costCodeId'
            rules={[
                {
                    required: true,
                    message: 'This field is required.',
                },
            ]}
        >
            <Select
                showSearch
                placeholder="Select a Cost Code"
                optionFilterProp="children"
            >
                {
                    job?.costCodes?.filter(x => x.isToTrackEmployeeAndEquipmentTime).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>)
                }

            </Select>
        </Form.Item>


        <Form.Item
            label='Type of Work'
            name='workTypeId'
            rules={[
                {
                    required: true,
                },
            ]}
            initialValue={prefillWorkType}
        >
            <Select
                showSearch
                placeholder="Select a Work Type"
                optionFilterProp="children"
                onChange={onWorkTypeSelect}
            >
                {workTypeList?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
            </Select>
        </Form.Item>

        <Form.Item
            label='Comments'
            name='description'
            rules={[
                {
                    required: false,
                },
            ]}
            initialValue={prefillDescription}
        >
            <Input.TextArea />
        </Form.Item>

        {(workType?.isEquipmentRequired || production?.productionEquipments?.length > 0) &&
            <Form.List name="productionEquipments">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Card key={field.key} style={{ marginBottom: 24 }}>
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) =>
                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                    }
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    {() => (
                                        <Form.Item
                                            {...field}
                                            label="Equipment"
                                            name={[field.name, 'equipmentId']}
                                            fieldKey={[field.fieldKey, 'equipmentId']}
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Select>
                                                {
                                                    job?.equipmentUtilization.map(x => x.equipment).map(item => (
                                                        <Option key={item.name} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="Hours"
                                    name={[field.name, 'hoursUsed']}
                                    fieldKey={[field.fieldKey, 'hoursUsed']}
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <InputNumber />
                                </Form.Item>

                                <Form.Item
                                    {...field}
                                    label="Fuel (L)"
                                    name={[field.name, 'litersOfFuel']}
                                    fieldKey={[field.fieldKey, 'litersOfFuel']}
                                    rules={[{ required: false, message: 'Required' }]}
                                >
                                    <InputNumber />
                                </Form.Item>

                                <Form.Item
                                    {...field}
                                    label="Notes"
                                    name={[field.name, 'notes']}
                                    fieldKey={[field.fieldKey, 'notes']}
                                    rules={[{ required: false, message: 'Required' }]}
                                >
                                    <Input.TextArea />
                                </Form.Item>

                                {
                                    !readOnly && !isConfirming && <Button block type="dashed" danger icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}>Remove</Button>
                                }
                            </Card>
                        ))}

                        <Form.Item>
                            {
                                !readOnly && !isConfirming && <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Equipment Usage
                                </Button>}
                        </Form.Item>
                    </>
                )}
            </Form.List>
        }
        {!readOnly && !isConfirming && <Form.Item>
            <Button type='primary' htmlType='submit'>
                {isEditMode ? "Update" : "Submit"}
            </Button>
        </Form.Item>}
    </Form>

    return (
        <ConfigProvider5 prefixCls="ant5">
            {contextHolder}
            {!readOnly && <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={24}>
                    <Text type="secondary">Time-in</Text>
                    <Title level={4} style={{ marginTop: 8 }}>Submit Time Record</Title>
                </Col>
            </Row>}
            <Card
                style={{
                    marginTop: 16,
                    maxWidth: 800,
                    minWidth: "90vw",
                    minHeight: "100vh"
                }}>
                {formUi}

                <Modal
                    centered
                    open={isConfirming}
                    title="Preview Changes"
                    onCancel={() => setIsConfirming(false)}
                    footer={[
                        <Button key="back" disabled={loading} onClick={() => setIsConfirming(false)}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={() => onFinish(false)}>
                            Save & Close
                        </Button>,
                        <Button
                            key="link"
                            loading={loading} onClick={() => onFinish(true)}
                        >
                            Save & Add More
                        </Button>,
                    ]}
                >

                    {/* <Alert
                        message="Preview Changes"
                        showIcon
                        description="Please review your time record and equipment usage before submitting."
                        type="info"
                        style={{ marginBottom: 24 }}
                    /> */}
                    {formUi}
                    <Descriptions layout="vertical" style={{ marginTop: 12, marginBottom: 12 }} bordered items={[
                        {
                            key: '1',
                            label: 'Total Hours Worked',
                            children: computedHours,
                        },
                        {
                            key: '2',
                            label: 'Total Equipment Hours   ',
                            children: form.getFieldsValue()?.productionEquipments?.reduce((prev, curr) => prev + curr.hoursUsed, 0),
                        },
                        {
                            key: '3',
                            label: 'No. of Equipment Used',
                            children: form.getFieldsValue()?.productionEquipments?.length,
                        },
                    ]} />
                </Modal>
            </Card>
            {/* {<MobileNav />} */}
        </ConfigProvider5 >
    );
};