import {
  SyncOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider as ConfigProvider5,
  DatePicker,
  Descriptions,
  Drawer,
  FloatButton,
  Form,
  Layout,
  List,
  Modal,
  Row,
  Spin,
  Skeleton,
  Statistic,
  Tag,
  Typography
} from 'antd-v5';
import dayjs from 'dayjs';
import React from 'react';
import Auth from '../../Auth';
import { MobileNav } from './Nav';
const { Content } = Layout;
const { Text, Title } = Typography;

const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [week, setWeek] = React.useState(dayjs());
  const [timeRequests, setTimeRequests] = React.useState([]);
  const [timeRecords, setTimeRecords] = React.useState([]);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});
  const [selector] = Form.useForm();

  const fetchTimeRecords = () => {
    setLoading(true);
    const startAt = dayjs(week).startOf("week").toISOString();
    const requestEndsAt = dayjs(week).endOf("week").toISOString();

    fetch(`/api/jobs/productions/time-records?userId=${Auth.getCurrentUser()?.id}&startsAt=${startAt}&endsAt=${requestEndsAt}`)
      .then((response) => response.json())
      .then((response) => {
        const filtered = response.filter(p => dayjs(p.requestAt) >= dayjs(week).startOf("week") && dayjs(p.requestAt) <= dayjs(week).endOf("week"))
        setTimeRecords(response);
        setLoading(false);
      })
  }

  const onWeekSelect = (value) => setWeek(value);
  const openSelectorDrawer = () => setOpenSelector(true);
  const closeSelectorDrawer = () => setOpenSelector(false);

  const onPreviousWeekSelect = () => {
    const prevWeek = dayjs(week).subtract(1, "week");
    setWeek(prevWeek);
  }

  const onNextWeekSelect = () => {
    const nextWeek = dayjs(week).add(1, "week");
    setWeek(nextWeek);
  }

  const onSelectorFormSubmit = async () => {
    fetchTimeRecords();
    closeSelectorDrawer();
  }

  React.useEffect(() => {
    fetchTimeRecords()
  }, [week]);

  return (
    <ConfigProvider5 prefixCls="ant5">

      <Spin spinning={loading} fullscreen={true} />

      <Content style={{
        marginTop: 16,
        maxWidth: 800,
        width: "100%"
      }}>
        <Modal open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
          <Descriptions
            title="Record Details"
            colon=""
            layout="vertical"
            items={[
              {
                key: '1d',
                label: 'Date',
                children:
                  <>
                    {selectedRecord?.producedEndsAt ?
                      <div>
                        <div>{dayjs(selectedRecord?.producedAt).format("YYYY-MM-DD")} {dayjs(selectedRecord?.producedAt).format("HH:mm")} to {dayjs(selectedRecord?.producedEndsAt).format("HH:mm")}</div>
                      </div> :
                      dayjs(selectedRecord?.producedAt).format("YYYY-MM-DD")}
                  </>

              },
              {
                key: 'h2',
                label: 'Hours',
                children: selectedRecord.quantity,
              },
              {
                key: 'j2',
                label: 'Job',
                children: <>{selectedRecord?.costCode?.job?.name} - {selectedRecord?.costCode?.job?.description}</>,
              },
              {
                key: '',
                label: 'Cost Code',
                children: <>{selectedRecord?.costCode?.name} - {selectedRecord?.costCode?.description}</>,
              },
              {
                key: '3',
                label: 'Type of Work',
                children: <>{selectedRecord?.workType?.name}</>,
              },
              {
                key: 'c4',
                label: 'Notes',
                children: <>{selectedRecord?.description ?? "Empty"}</>,
              },
              {
                key: '4',
                label: 'Ref. ID',
                children: selectedRecord?.id,
                span: 3,
              },
              {
                key: '4e',
                label: 'Equipment Usage',
                span: 3,
                children:
                  <List
                    bordered
                    loading={loading}
                    dataSource={selectedRecord?.productionEquipments}
                    renderItem={(record) => {
                      console.log({ record });
                      return (
                        <List.Item
                          bordered
                        >
                          <Descriptions
                            colon={null}
                            layout="horizontal"
                            items={[
                              {
                                key: '4x',
                                label: 'Equipment',
                                children: record.equipment?.name,
                                span: 2,
                              },
                              {
                                key: '4y',
                                label: 'Hours Used',
                                children: record.hoursUsed,
                                span: 2,
                              },
                              {
                                key: '4z',
                                label: 'Liters of Fuel',
                                children: record.litersOfFuel,
                                span: 2,
                              },
                              {
                                key: '4s',
                                label: 'Notes',
                                children: record.notes,
                                span: 2,
                              },
                            ]} />
                        </List.Item>
                      )
                    }
                    }
                  />
              },

            ]} />

        </Modal>
        <div style={{
          background: "#001529",
          margin: -16,
          padding: 16,
          marginBottom: 16
        }}>
          <Row gutter={16}>
            <Col span={24}>
              <Text style={{ color: "#fff" }} type="secondary">Submitted Entries</Text>
              <Title level={4} style={{ marginTop: 8, color: "#fff" }}>My Time Records</Title>
            </Col>
          </Row>
        </div>

        <Layout
          className="site-layout-background"
          style={{
            borderRadius: 8,
            marginTop: 16
          }}
        >

          <Content
            style={{
              minHeight: "100vh",
              padding: '12px 12px',
            }}
          >
            {
              week &&
              <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col xs={2} sm={4} md={6} lg={8} xl={10} >
                  <Button type="primary" shape="round" onClick={onPreviousWeekSelect}>
                    <ArrowLeftOutlined />
                  </Button>
                </Col>
                <Col xs={20} sm={16} md={12} lg={8} xl={4} style={{ textAlign: "center" }}>
                  <Text type="secondary">Week</Text>
                  <Title level={4} style={{ marginTop: 24 }}>{week ? `${dayjs(week).startOf("week").format("MMMM D")} to ${dayjs(week).endOf("week").format("MMMM D")}` : ""}</Title>
                </Col>
                <Col xs={2} sm={4} md={6} lg={8} xl={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" shape="round" onClick={onNextWeekSelect}>
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            }
            {!loading &&
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={timeRecords}
                renderItem={(record) => {
                  return (
                    <List.Item
                      onClick={() => {
                        setSelectedRecord(record);
                        setIsModalOpen(true);
                      }}
                      actions={[
                        <Button
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => window.open(`#/app/time-record-form/${record.id}/edit`)}
                          disabled={record.isApproved}
                        >
                        </Button>
                      ]}>
                      <Skeleton avatar title={false} loading={loading} active>
                        <List.Item.Meta
                          title={<>{record.costCode.job.name} - {record.costCode.job.description}</>}
                          description={
                            <div>
                              {record.costCode.name} - {record.costCode.description} <br />

                              <Tag bordered={false} color="cyan">{record.quantity} hour(s)</Tag>
                              {record.isApproved == null && <Tag bordered={false} color="processing">Pending</Tag>}
                              {record.isApproved == true && <Tag bordered={false} color="success">Approved</Tag>}
                              {record.isApproved == false && <Tag bordered={false} color="error">Not Approved</Tag>}
                            </div>}
                        />
                        <div>
                          <Text type="secondary" style={{ textAlign: "left" }}>
                            {
                              record.producedEndsAt ?
                                <div>
                                  <div>{dayjs(record.producedAt).format("YYYY-MM-DD")}</div>
                                  <div>{dayjs(record.producedAt).format("HH:mm")} to {dayjs(record.producedEndsAt).format("HH:mm")}</div>
                                </div> :
                                dayjs(record.producedAt).format("YYYY-MM-DD")
                            }
                          </Text>
                        </div>
                      </Skeleton>
                    </List.Item>
                  )
                }}
              />
            }
          </Content>
        </Layout>

        <Card style={{ marginTop: 24 }}>
          <Statistic title="Week Hours Submitted" value={timeRecords.reduce((n, { quantity }) => n + quantity, 0)} suffix="hour(s)" />
        </Card>

        <Drawer
          title="Job Schedule"
          placement="right"
          onClose={closeSelectorDrawer}
          open={openSelector}
        >
          <Form
            form={selector}
            onFinish={onSelectorFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="week"
              label="Week"
              required
            >
              <DatePicker
                style={{
                  width: "100%"
                }}
                onChange={onWeekSelect}
                picker="week" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </Drawer>



        <FloatButton.Group size="large" shape="square" style={{ right: 24, bottom: 120 }}>
          <FloatButton icon={<SyncOutlined />} onClick={openSelectorDrawer} />
          <FloatButton.BackTop visibilityHeight={300} />
        </FloatButton.Group>
      </Content >
      {/* <MobileNav /> */}
    </ConfigProvider5 >
  )
}

export default App;